import React from 'react'

import NFLLogo from '../assets/images/nfllogo.png'
import NBALogo from '../assets/images/nba-logo.png'
import MLBLogo from '../assets/images/mlb-logo.png'
import DKLogo from '../assets/images/logos/dk.png'
import FDLogo from '../assets/images/logos/fd.png'
import YALogo from '../assets/images/logos/ya.png'
import PGALogo from '../assets/images/pga-logo.png'
import NFLWeekPicker from './nfl-week-picker'
import MLBDatePicker from './mlb-date-picker'
import PGADatePicker from './pga-date-picker'

const siteToLogoMap = {
  'dk': (
    <img src={DKLogo} style={{height: '30px', marginRight: '10px'}} />
  ),
  'fd': (
    <img src={FDLogo} style={{height: '30px', marginRight: '10px'}} />
  ),
  'ya': (
    <img src={YALogo} style={{height: '30px', marginRight: '10px'}} />
  )
}

const sportToLogoMap = {
  'nfl': (
    <img src={NFLLogo} style={{height: '30px', marginRight: '10px'}} />
  ),
  'nba': (
    <img src={NBALogo} style={{height: '32px', marginRight: '10px'}} />
  ),
  'mlb': (
    <img src={MLBLogo} style={{height: '22px', marginRight: '10px'}} />
  ),
  'pga': (
    <img src={PGALogo} style={{height: '32px', marginRight: '0px', marginTop: '5px'}} />
  ),
}

export const SectionHeaderTitle = ({sport, site, counter, season, changeDateInfo, updateSite}) => {
  let body = counter
  switch(sport) {
    case 'nfl':
      body = (<NFLWeekPicker selectedWeek={counter} selectedSeason={season} changeDateInfo={changeDateInfo} site={site} updateSite={updateSite} />)
      break
    case 'mlb':
      body = (<MLBDatePicker selectedDate={counter} selectedSeason={season} changeDateInfo={changeDateInfo} site={site} updateSite={updateSite} />)
      break
    case 'nba':
      body = (<MLBDatePicker selectedDate={counter} selectedSeason={season} changeDateInfo={changeDateInfo} site={site} updateSite={updateSite} />)
      break
    case 'pga':
      body = (<PGADatePicker selectedDate={counter} selectedSeason={season} changeDateInfo={changeDateInfo} site={site} updateSite={updateSite} />)
      break
    default:
      break
  }

  return (
    <h3>
      {sportToLogoMap[sport]}
      <span style={{padding: '5px', marginTop: '5px'}}>
        {
          body
        }  
      </span>
    </h3>
  )
}