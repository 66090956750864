import { Menu, Radio, Col } from 'antd'
import styled from 'styled-components'
import { framed, overridePrimary, overridePlayerText, overrideBackground, overrideColor } from '../constants/partner'

export const PlayerText = styled.span`
  cursor: pointer;
  font-weight: 600;
  color: ${framed ? (overridePlayerText || overridePrimary) : '#1d3557'};
  &:hover {
    color: #1890ff;
  }
`

export const StyledMenu = styled(Menu)`
  background: ${framed && overrideBackground ? overrideBackground : null} !important;
  color: ${framed ? (overrideColor || overridePlayerText) : '#fff'} !important;

  .ant-menu-item-selected {
    color: #a8aaba !important;
  }
`

export const SwitchCol = styled(Col)`
  .ant-switch {
    background-color: #1890ff;
    background-image: none;
  }
  .ant-switch-checked {
    background-color: #ffa500;
  }
`

export const StyledRadioButton = styled(Radio.Button)`
  .ant-radio-checked .ant-radio-inner{
    border-color: a8aaba !important ;
  }

  .ant-radio-checked .ant-radio-inner:after{
    background-color: a8aaba;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: a8aaba ;
  }
`