import { Radio } from "antd"
import styled from 'styled-components'

const RadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper-checked {
    color: white !important;
    background: #031cad;
  }
`

export default RadioGroup