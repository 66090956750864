import React from 'react'
import { Dropdown, Space, DatePicker } from 'antd'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { weekOptions, seasonOptions } from '../utils/nfl-week'
import { CaretDownOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import DKLogo from '../assets/images/logos/dk.png'
import FDLogo from '../assets/images/logos/fd.png'

const DrowdownContainer = styled.div`
  display: inline-block;
`
const SmallCarrot = styled.span`
  font-size: 10px;
  margin-left: -5px;
`

const siteToLogoMap = {
  'dk': (
    <img src={DKLogo} style={{height: '28px'}} />
  )
}

const dateFormat = 'YYYY-MM-DD';

const PGADatePicker = ({ selectedDate, selectedSeason, changeDateInfo, site, updateSite }) => {
  const _siteOptions = Object.keys(siteToLogoMap).map(site => {
    return {
      label: <div onClick={() => {updateSite(site)}}> {siteToLogoMap[site]} </div>,
      key: site
    }
  })

  const setNewDate = (newDate) => {
    changeDateInfo({counter: newDate.format('YYYY-MM-DD'), season: selectedSeason})
  }
  return (
    <DrowdownContainer>
      <Dropdown
        menu={{items: _siteOptions}}
        trigger={['click']}
      >
        <Space>
          {siteToLogoMap[site]}<SmallCarrot style={{marginRight: '10px'}}><CaretDownOutlined /></SmallCarrot>
        </Space>
      </Dropdown>
      <DatePicker value={dayjs(selectedDate)} format={dateFormat} allowClear={false} onChange={(d) => {setNewDate(d)}}/>

    </DrowdownContainer>
  )
}

export default PGADatePicker
